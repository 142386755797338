import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`While creating a function that uses `}<inlineCode parentName="p">{`logging.info`}</inlineCode>{` to log useful information on the first run of `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid/opsdroid"
      }}>{`Opsdroid`}</a>{` the percentage of `}<inlineCode parentName="p">{`coverall`}</inlineCode>{` dropped quite a bit. To counter that a test had to be created to assert if the `}<inlineCode parentName="p">{`logging.info`}</inlineCode>{` was called or not.`}</p>
    <p>{`Opsdroid uses a file named `}<inlineCode parentName="p">{`configuration.yml`}</inlineCode>{` to keep track of all the configuration details. Upon the suggestion of Jacob(the creator and maintainer of the project) a `}<inlineCode parentName="p">{`welcome-message: true`}</inlineCode>{` line was added to the configuration file in case the user wishes to hide the welcome message.`}</p>
    <h1 {...{
      "id": "the-function",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-function",
        "aria-label": "the function permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Function`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`welcome_message`}</inlineCode>{` function is a very basic function that uses the `}<inlineCode parentName="p">{`logging.info`}</inlineCode>{` to log a quick get started information about Opsdroid.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`  def welcome_message(config):
      """Add welcome message if set to true in configuration."""
      try:
          if config['welcome-message']:
              LOGGER.info("=" * 40)
              LOGGER.info("You can customise your opsdroid by modifying "
                          "your configuration.yaml")
              LOGGER.info("Read more at: "
                          "http://opsdroid.readthedocs.io/#configuration")
             LOGGER.info("Watch the Get Started Videos at: "
                         "http://bit.ly/2fnC0Fh")
             LOGGER.info("Install Opsdroid Desktop at: "
                         "https://github.com/opsdroid/opsdroid-desktop/releases")
             LOGGER.info("=" * 40)
     except KeyError:
         pass
`}</code></pre>
    <h1 {...{
      "id": "the-test",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-test",
        "aria-label": "the test permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Test`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`   def test_welcome_message(self):
      config = {"welcome-message": True}
      with mock.patch('opsdroid.__main__.LOGGER.info') as logmock:
        opsdroid.welcome_message(config)
        self.assertTrue(logmock.called)
`}</code></pre>
    <ul>
      <li parentName="ul">{`We start the function by creating a dummy config file that will always return the welcome message.`}</li>
      <li parentName="ul">{`On line 3 we patch the `}<inlineCode parentName="li">{`LOGGER.info`}</inlineCode>{` call from `}<inlineCode parentName="li">{`opsdroid__main__`}</inlineCode>{` (where the welcome_message function is located)`}</li>
      <li parentName="ul">{`On line 4 we make a call to the `}<inlineCode parentName="li">{`welcome_message`}</inlineCode>{` function using the dummy config file (which will always return the `}<inlineCode parentName="li">{`LOGGER.info`}</inlineCode>{` lines)`}</li>
      <li parentName="ul">{`On line 5 `}<inlineCode parentName="li">{`logmock.called`}</inlineCode>{` will return a boolean whether the mocked object has been called. Since we are sure that the dummy config file will always return True we do a simple `}<inlineCode parentName="li">{`assertTrue`}</inlineCode>{` to see if `}<inlineCode parentName="li">{`opsdroid.__main__.LOGGER.info`}</inlineCode>{` was called.`}</li>
    </ul>
    <h1 {...{
      "id": "update-an-easier-way",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#update-an-easier-way",
        "aria-label": "update an easier way permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`(Update) An easier way`}</h1>
    <p>{`When I wrote this pos, back  in September of 2017, it seemed that there was no way to test if a logging message was logged. That changed in Feb when the unittest framework was updated and introduced a brilliant new assert.`}</p>
    <p>{`Now, you can quickly check if a message was logged with the assert `}<inlineCode parentName="p">{`self.assertLogs(logger, level)`}</inlineCode>{`.
Let's say that your logger is called `}<inlineCode parentName="p">{`_Logger`}</inlineCode>{` and you are currently logging the message "hello" with the following code `}<inlineCode parentName="p">{`_Logger.info("hello")`}</inlineCode>{` for you to assert if the message was logged all you need to do is `}<inlineCode parentName="p">{`self.assertLogs('_Logger', 'info')`}</inlineCode>{`.`}</p>
    <p>{`As you can see, three lines of code are just reduced into a single one (or two if you need to call the function), which is great because it makes the testing code much more readable.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      